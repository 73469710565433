export function gql(strings, ...args) {
  let str = "";
  strings.forEach((string, i) => {
    str += string + (args[i] || "");
  });
  return str;
}
export const HomePartsFragmentDoc = gql`
    fragment HomeParts on Home {
  __typename
  header {
    __typename
    title
    subtitle
    image
  }
  opening {
    __typename
    title
    paragraph
  }
  treatmentsCTA {
    __typename
    title
    subtitle
  }
  blogCTA {
    __typename
    title
    subtitle
  }
  cta {
    __typename
    image
    title
    description
    button {
      __typename
      label
      link
    }
    buttonTwo {
      __typename
      label
      link
    }
  }
  partnerships {
    __typename
    title
    partners {
      __typename
      name
      logo
      link
    }
  }
  seo {
    __typename
    title
    description
    keywords
  }
}
    `;
export const TreatmentsPartsFragmentDoc = gql`
    fragment TreatmentsParts on Treatments {
  __typename
  header {
    __typename
    title
    subtitle
    image
  }
  treatments {
    __typename
    treatmentList {
      __typename
      treatment {
        ... on Treatment {
          __typename
          title
          header {
            __typename
            title
            subtitle
            image
          }
          overview {
            __typename
            points {
              __typename
              point
            }
          }
          sections {
            __typename
            pretitle
            title
            subSections {
              __typename
              title
              description
              points {
                __typename
                title
                description
              }
            }
          }
          cta {
            __typename
            title
            subtitle
            button {
              __typename
              label
              link
            }
          }
          faqs {
            __typename
            questions {
              __typename
              question
              answer
            }
          }
          seo {
            __typename
            title
            description
            keywords
          }
        }
        ... on Document {
          _sys {
            filename
            basename
            hasReferences
            breadcrumbs
            path
            relativePath
            extension
          }
          id
        }
      }
    }
  }
  cta {
    __typename
    title
    subtitle
    button {
      __typename
      label
      link
    }
  }
  seo {
    __typename
    title
    description
    keywords
  }
}
    `;
export const TreatmentPartsFragmentDoc = gql`
    fragment TreatmentParts on Treatment {
  __typename
  title
  header {
    __typename
    title
    subtitle
    image
  }
  overview {
    __typename
    points {
      __typename
      point
    }
  }
  sections {
    __typename
    pretitle
    title
    subSections {
      __typename
      title
      description
      points {
        __typename
        title
        description
      }
    }
  }
  cta {
    __typename
    title
    subtitle
    button {
      __typename
      label
      link
    }
  }
  faqs {
    __typename
    questions {
      __typename
      question
      answer
    }
  }
  seo {
    __typename
    title
    description
    keywords
  }
}
    `;
export const AboutPartsFragmentDoc = gql`
    fragment AboutParts on About {
  __typename
  header {
    __typename
    title
    subtitle
    image
  }
  opening {
    __typename
    title
    paragraph
    image
    imageTwo
    button {
      __typename
      label
      link
    }
    titleTwo
    paragraphTwo
    imageThree
    buttonTwo {
      __typename
      label
      link
    }
  }
  team {
    __typename
    title
    subtitle
    doctors {
      __typename
      role
      name
      image
      bio
      moreInfo {
        __typename
        sections {
          __typename
          title
          info
        }
      }
    }
    members {
      __typename
      name
      role
      bio
      image
    }
  }
  whyUs {
    __typename
    pretitle
    title
    paragraph
    button {
      __typename
      label
      link
    }
    image
  }
  sellingPoints {
    __typename
    points {
      __typename
      title
      paragraph
    }
  }
  treatmentsCta {
    __typename
    title
    button {
      __typename
      label
      link
    }
  }
  seo {
    __typename
    title
    description
    keywords
  }
}
    `;
export const ContactPartsFragmentDoc = gql`
    fragment ContactParts on Contact {
  __typename
  header {
    __typename
    title
    subtitle
  }
  form {
    __typename
    image
    successTitle
    successMessage
  }
  contact {
    __typename
    email
    phone
    whatsapp
  }
  locations {
    __typename
    title
    addresses {
      __typename
      title
      address
      phone
      email
      mapLink
      coordinates
      clinicId
      appointmentTypeId
    }
  }
  seo {
    __typename
    title
    description
    keywords
  }
}
    `;
export const ReferPatientsPartsFragmentDoc = gql`
    fragment ReferPatientsParts on ReferPatients {
  __typename
  header {
    __typename
    title
    description
  }
  form {
    __typename
    image
    successTitle
    successMessage
  }
  faqs {
    __typename
    questions {
      __typename
      question
      answer
    }
  }
  seo {
    __typename
    title
    description
    keywords
  }
}
    `;
export const BookAppointmentPartsFragmentDoc = gql`
    fragment BookAppointmentParts on BookAppointment {
  __typename
  header {
    __typename
    title
    subtitle
  }
  form {
    __typename
    image
    successTitle
    successMessage
  }
  faqs {
    __typename
    questions {
      __typename
      question
      answer
    }
  }
  seo {
    __typename
    title
    description
    keywords
  }
}
    `;
export const BlogPagePartsFragmentDoc = gql`
    fragment BlogPageParts on BlogPage {
  __typename
  header {
    __typename
    title
    subtitle
    image
  }
  seo {
    __typename
    title
    description
    keywords
  }
}
    `;
export const PostPartsFragmentDoc = gql`
    fragment PostParts on Post {
  __typename
  title
  summary
  image
  body
  date
  published
  keywords
}
    `;
export const ResourcesPagePartsFragmentDoc = gql`
    fragment ResourcesPageParts on ResourcesPage {
  __typename
  header {
    __typename
    title
    subtitle
    image
  }
  seo {
    __typename
    title
    description
    keywords
  }
}
    `;
export const ResourcePartsFragmentDoc = gql`
    fragment ResourceParts on Resource {
  __typename
  title
  summary
  image
  body
  date
  published
  keywords
}
    `;
export const LegalPartsFragmentDoc = gql`
    fragment LegalParts on Legal {
  __typename
  title
  body
  seo {
    __typename
    title
    description
    keywords
  }
}
    `;
export const GlobalPartsFragmentDoc = gql`
    fragment GlobalParts on Global {
  __typename
  seo {
    __typename
    title
    description
    keywords
    image
  }
  socials {
    __typename
    youtube
    facebook
    instagram
  }
}
    `;
export const BlogListDocument = gql`
    query blogList($first: Float, $filter: PostFilter) {
  postConnection(first: $first, filter: $filter) {
    edges {
      node {
        ... on Document {
          _sys {
            filename
          }
        }
        id
        title
        summary
        image
        date
        published
      }
    }
  }
}
    `;
export const BlogPostsSummaryDocument = gql`
    query blogPostsSummary($first: Float, $filter: PostFilter) {
  postConnection(first: $first, filter: $filter) {
    edges {
      node {
        ... on Document {
          _sys {
            filename
            basename
            breadcrumbs
            path
            relativePath
            extension
          }
        }
        id
        title
        summary
        image
        date
        published
      }
    }
  }
}
    `;
export const ResourceListDocument = gql`
    query resourceList($first: Float, $filter: ResourceFilter) {
  resourceConnection(first: $first, filter: $filter) {
    edges {
      node {
        ... on Document {
          _sys {
            filename
          }
        }
        id
        title
        summary
        image
        date
        published
      }
    }
  }
}
    `;
export const TreatmentsListDocument = gql`
    query treatmentsList($relativePath: String!) {
  treatments(relativePath: $relativePath) {
    header {
      __typename
      title
      subtitle
      image
    }
    treatments {
      treatmentList {
        treatment {
          ... on Treatment {
            title
            header {
              title
              subtitle
              image
            }
            ... on Document {
              _sys {
                filename
              }
            }
          }
        }
      }
    }
    cta {
      __typename
      title
      subtitle
      button {
        __typename
        label
        link
      }
    }
    seo {
      __typename
      title
      description
      keywords
    }
  }
}
    `;
export const TreatmentsSummaryDocument = gql`
    query treatmentsSummary($relativePath: String!) {
  treatments(relativePath: $relativePath) {
    ... on Document {
      _sys {
        filename
        basename
        breadcrumbs
        path
        relativePath
        extension
      }
      id
    }
    header {
      title
      subtitle
      image
    }
    treatments {
      treatmentList {
        treatment {
          ... on Treatment {
            title
            header {
              title
              subtitle
              image
            }
          }
          ... on Document {
            _sys {
              filename
              basename
              breadcrumbs
              path
              relativePath
              extension
            }
          }
        }
      }
    }
  }
}
    `;
export const HomeDocument = gql`
    query home($relativePath: String!) {
  home(relativePath: $relativePath) {
    ... on Document {
      _sys {
        filename
        basename
        hasReferences
        breadcrumbs
        path
        relativePath
        extension
      }
      id
    }
    ...HomeParts
  }
}
    ${HomePartsFragmentDoc}`;
export const HomeConnectionDocument = gql`
    query homeConnection($before: String, $after: String, $first: Float, $last: Float, $sort: String, $filter: HomeFilter) {
  homeConnection(
    before: $before
    after: $after
    first: $first
    last: $last
    sort: $sort
    filter: $filter
  ) {
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
    totalCount
    edges {
      cursor
      node {
        ... on Document {
          _sys {
            filename
            basename
            hasReferences
            breadcrumbs
            path
            relativePath
            extension
          }
          id
        }
        ...HomeParts
      }
    }
  }
}
    ${HomePartsFragmentDoc}`;
export const TreatmentsDocument = gql`
    query treatments($relativePath: String!) {
  treatments(relativePath: $relativePath) {
    ... on Document {
      _sys {
        filename
        basename
        hasReferences
        breadcrumbs
        path
        relativePath
        extension
      }
      id
    }
    ...TreatmentsParts
  }
}
    ${TreatmentsPartsFragmentDoc}`;
export const TreatmentsConnectionDocument = gql`
    query treatmentsConnection($before: String, $after: String, $first: Float, $last: Float, $sort: String, $filter: TreatmentsFilter) {
  treatmentsConnection(
    before: $before
    after: $after
    first: $first
    last: $last
    sort: $sort
    filter: $filter
  ) {
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
    totalCount
    edges {
      cursor
      node {
        ... on Document {
          _sys {
            filename
            basename
            hasReferences
            breadcrumbs
            path
            relativePath
            extension
          }
          id
        }
        ...TreatmentsParts
      }
    }
  }
}
    ${TreatmentsPartsFragmentDoc}`;
export const TreatmentDocument = gql`
    query treatment($relativePath: String!) {
  treatment(relativePath: $relativePath) {
    ... on Document {
      _sys {
        filename
        basename
        hasReferences
        breadcrumbs
        path
        relativePath
        extension
      }
      id
    }
    ...TreatmentParts
  }
}
    ${TreatmentPartsFragmentDoc}`;
export const TreatmentConnectionDocument = gql`
    query treatmentConnection($before: String, $after: String, $first: Float, $last: Float, $sort: String, $filter: TreatmentFilter) {
  treatmentConnection(
    before: $before
    after: $after
    first: $first
    last: $last
    sort: $sort
    filter: $filter
  ) {
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
    totalCount
    edges {
      cursor
      node {
        ... on Document {
          _sys {
            filename
            basename
            hasReferences
            breadcrumbs
            path
            relativePath
            extension
          }
          id
        }
        ...TreatmentParts
      }
    }
  }
}
    ${TreatmentPartsFragmentDoc}`;
export const AboutDocument = gql`
    query about($relativePath: String!) {
  about(relativePath: $relativePath) {
    ... on Document {
      _sys {
        filename
        basename
        hasReferences
        breadcrumbs
        path
        relativePath
        extension
      }
      id
    }
    ...AboutParts
  }
}
    ${AboutPartsFragmentDoc}`;
export const AboutConnectionDocument = gql`
    query aboutConnection($before: String, $after: String, $first: Float, $last: Float, $sort: String, $filter: AboutFilter) {
  aboutConnection(
    before: $before
    after: $after
    first: $first
    last: $last
    sort: $sort
    filter: $filter
  ) {
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
    totalCount
    edges {
      cursor
      node {
        ... on Document {
          _sys {
            filename
            basename
            hasReferences
            breadcrumbs
            path
            relativePath
            extension
          }
          id
        }
        ...AboutParts
      }
    }
  }
}
    ${AboutPartsFragmentDoc}`;
export const ContactDocument = gql`
    query contact($relativePath: String!) {
  contact(relativePath: $relativePath) {
    ... on Document {
      _sys {
        filename
        basename
        hasReferences
        breadcrumbs
        path
        relativePath
        extension
      }
      id
    }
    ...ContactParts
  }
}
    ${ContactPartsFragmentDoc}`;
export const ContactConnectionDocument = gql`
    query contactConnection($before: String, $after: String, $first: Float, $last: Float, $sort: String, $filter: ContactFilter) {
  contactConnection(
    before: $before
    after: $after
    first: $first
    last: $last
    sort: $sort
    filter: $filter
  ) {
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
    totalCount
    edges {
      cursor
      node {
        ... on Document {
          _sys {
            filename
            basename
            hasReferences
            breadcrumbs
            path
            relativePath
            extension
          }
          id
        }
        ...ContactParts
      }
    }
  }
}
    ${ContactPartsFragmentDoc}`;
export const ReferPatientsDocument = gql`
    query referPatients($relativePath: String!) {
  referPatients(relativePath: $relativePath) {
    ... on Document {
      _sys {
        filename
        basename
        hasReferences
        breadcrumbs
        path
        relativePath
        extension
      }
      id
    }
    ...ReferPatientsParts
  }
}
    ${ReferPatientsPartsFragmentDoc}`;
export const ReferPatientsConnectionDocument = gql`
    query referPatientsConnection($before: String, $after: String, $first: Float, $last: Float, $sort: String, $filter: ReferPatientsFilter) {
  referPatientsConnection(
    before: $before
    after: $after
    first: $first
    last: $last
    sort: $sort
    filter: $filter
  ) {
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
    totalCount
    edges {
      cursor
      node {
        ... on Document {
          _sys {
            filename
            basename
            hasReferences
            breadcrumbs
            path
            relativePath
            extension
          }
          id
        }
        ...ReferPatientsParts
      }
    }
  }
}
    ${ReferPatientsPartsFragmentDoc}`;
export const BookAppointmentDocument = gql`
    query bookAppointment($relativePath: String!) {
  bookAppointment(relativePath: $relativePath) {
    ... on Document {
      _sys {
        filename
        basename
        hasReferences
        breadcrumbs
        path
        relativePath
        extension
      }
      id
    }
    ...BookAppointmentParts
  }
}
    ${BookAppointmentPartsFragmentDoc}`;
export const BookAppointmentConnectionDocument = gql`
    query bookAppointmentConnection($before: String, $after: String, $first: Float, $last: Float, $sort: String, $filter: BookAppointmentFilter) {
  bookAppointmentConnection(
    before: $before
    after: $after
    first: $first
    last: $last
    sort: $sort
    filter: $filter
  ) {
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
    totalCount
    edges {
      cursor
      node {
        ... on Document {
          _sys {
            filename
            basename
            hasReferences
            breadcrumbs
            path
            relativePath
            extension
          }
          id
        }
        ...BookAppointmentParts
      }
    }
  }
}
    ${BookAppointmentPartsFragmentDoc}`;
export const BlogPageDocument = gql`
    query blogPage($relativePath: String!) {
  blogPage(relativePath: $relativePath) {
    ... on Document {
      _sys {
        filename
        basename
        hasReferences
        breadcrumbs
        path
        relativePath
        extension
      }
      id
    }
    ...BlogPageParts
  }
}
    ${BlogPagePartsFragmentDoc}`;
export const BlogPageConnectionDocument = gql`
    query blogPageConnection($before: String, $after: String, $first: Float, $last: Float, $sort: String, $filter: BlogPageFilter) {
  blogPageConnection(
    before: $before
    after: $after
    first: $first
    last: $last
    sort: $sort
    filter: $filter
  ) {
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
    totalCount
    edges {
      cursor
      node {
        ... on Document {
          _sys {
            filename
            basename
            hasReferences
            breadcrumbs
            path
            relativePath
            extension
          }
          id
        }
        ...BlogPageParts
      }
    }
  }
}
    ${BlogPagePartsFragmentDoc}`;
export const PostDocument = gql`
    query post($relativePath: String!) {
  post(relativePath: $relativePath) {
    ... on Document {
      _sys {
        filename
        basename
        hasReferences
        breadcrumbs
        path
        relativePath
        extension
      }
      id
    }
    ...PostParts
  }
}
    ${PostPartsFragmentDoc}`;
export const PostConnectionDocument = gql`
    query postConnection($before: String, $after: String, $first: Float, $last: Float, $sort: String, $filter: PostFilter) {
  postConnection(
    before: $before
    after: $after
    first: $first
    last: $last
    sort: $sort
    filter: $filter
  ) {
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
    totalCount
    edges {
      cursor
      node {
        ... on Document {
          _sys {
            filename
            basename
            hasReferences
            breadcrumbs
            path
            relativePath
            extension
          }
          id
        }
        ...PostParts
      }
    }
  }
}
    ${PostPartsFragmentDoc}`;
export const ResourcesPageDocument = gql`
    query resourcesPage($relativePath: String!) {
  resourcesPage(relativePath: $relativePath) {
    ... on Document {
      _sys {
        filename
        basename
        hasReferences
        breadcrumbs
        path
        relativePath
        extension
      }
      id
    }
    ...ResourcesPageParts
  }
}
    ${ResourcesPagePartsFragmentDoc}`;
export const ResourcesPageConnectionDocument = gql`
    query resourcesPageConnection($before: String, $after: String, $first: Float, $last: Float, $sort: String, $filter: ResourcesPageFilter) {
  resourcesPageConnection(
    before: $before
    after: $after
    first: $first
    last: $last
    sort: $sort
    filter: $filter
  ) {
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
    totalCount
    edges {
      cursor
      node {
        ... on Document {
          _sys {
            filename
            basename
            hasReferences
            breadcrumbs
            path
            relativePath
            extension
          }
          id
        }
        ...ResourcesPageParts
      }
    }
  }
}
    ${ResourcesPagePartsFragmentDoc}`;
export const ResourceDocument = gql`
    query resource($relativePath: String!) {
  resource(relativePath: $relativePath) {
    ... on Document {
      _sys {
        filename
        basename
        hasReferences
        breadcrumbs
        path
        relativePath
        extension
      }
      id
    }
    ...ResourceParts
  }
}
    ${ResourcePartsFragmentDoc}`;
export const ResourceConnectionDocument = gql`
    query resourceConnection($before: String, $after: String, $first: Float, $last: Float, $sort: String, $filter: ResourceFilter) {
  resourceConnection(
    before: $before
    after: $after
    first: $first
    last: $last
    sort: $sort
    filter: $filter
  ) {
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
    totalCount
    edges {
      cursor
      node {
        ... on Document {
          _sys {
            filename
            basename
            hasReferences
            breadcrumbs
            path
            relativePath
            extension
          }
          id
        }
        ...ResourceParts
      }
    }
  }
}
    ${ResourcePartsFragmentDoc}`;
export const LegalDocument = gql`
    query legal($relativePath: String!) {
  legal(relativePath: $relativePath) {
    ... on Document {
      _sys {
        filename
        basename
        hasReferences
        breadcrumbs
        path
        relativePath
        extension
      }
      id
    }
    ...LegalParts
  }
}
    ${LegalPartsFragmentDoc}`;
export const LegalConnectionDocument = gql`
    query legalConnection($before: String, $after: String, $first: Float, $last: Float, $sort: String, $filter: LegalFilter) {
  legalConnection(
    before: $before
    after: $after
    first: $first
    last: $last
    sort: $sort
    filter: $filter
  ) {
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
    totalCount
    edges {
      cursor
      node {
        ... on Document {
          _sys {
            filename
            basename
            hasReferences
            breadcrumbs
            path
            relativePath
            extension
          }
          id
        }
        ...LegalParts
      }
    }
  }
}
    ${LegalPartsFragmentDoc}`;
export const GlobalDocument = gql`
    query global($relativePath: String!) {
  global(relativePath: $relativePath) {
    ... on Document {
      _sys {
        filename
        basename
        hasReferences
        breadcrumbs
        path
        relativePath
        extension
      }
      id
    }
    ...GlobalParts
  }
}
    ${GlobalPartsFragmentDoc}`;
export const GlobalConnectionDocument = gql`
    query globalConnection($before: String, $after: String, $first: Float, $last: Float, $sort: String, $filter: GlobalFilter) {
  globalConnection(
    before: $before
    after: $after
    first: $first
    last: $last
    sort: $sort
    filter: $filter
  ) {
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
    totalCount
    edges {
      cursor
      node {
        ... on Document {
          _sys {
            filename
            basename
            hasReferences
            breadcrumbs
            path
            relativePath
            extension
          }
          id
        }
        ...GlobalParts
      }
    }
  }
}
    ${GlobalPartsFragmentDoc}`;
export function getSdk(requester) {
  return {
    blogList(variables, options) {
      return requester(BlogListDocument, variables, options);
    },
    blogPostsSummary(variables, options) {
      return requester(BlogPostsSummaryDocument, variables, options);
    },
    resourceList(variables, options) {
      return requester(ResourceListDocument, variables, options);
    },
    treatmentsList(variables, options) {
      return requester(TreatmentsListDocument, variables, options);
    },
    treatmentsSummary(variables, options) {
      return requester(TreatmentsSummaryDocument, variables, options);
    },
    home(variables, options) {
      return requester(HomeDocument, variables, options);
    },
    homeConnection(variables, options) {
      return requester(HomeConnectionDocument, variables, options);
    },
    treatments(variables, options) {
      return requester(TreatmentsDocument, variables, options);
    },
    treatmentsConnection(variables, options) {
      return requester(TreatmentsConnectionDocument, variables, options);
    },
    treatment(variables, options) {
      return requester(TreatmentDocument, variables, options);
    },
    treatmentConnection(variables, options) {
      return requester(TreatmentConnectionDocument, variables, options);
    },
    about(variables, options) {
      return requester(AboutDocument, variables, options);
    },
    aboutConnection(variables, options) {
      return requester(AboutConnectionDocument, variables, options);
    },
    contact(variables, options) {
      return requester(ContactDocument, variables, options);
    },
    contactConnection(variables, options) {
      return requester(ContactConnectionDocument, variables, options);
    },
    referPatients(variables, options) {
      return requester(ReferPatientsDocument, variables, options);
    },
    referPatientsConnection(variables, options) {
      return requester(ReferPatientsConnectionDocument, variables, options);
    },
    bookAppointment(variables, options) {
      return requester(BookAppointmentDocument, variables, options);
    },
    bookAppointmentConnection(variables, options) {
      return requester(BookAppointmentConnectionDocument, variables, options);
    },
    blogPage(variables, options) {
      return requester(BlogPageDocument, variables, options);
    },
    blogPageConnection(variables, options) {
      return requester(BlogPageConnectionDocument, variables, options);
    },
    post(variables, options) {
      return requester(PostDocument, variables, options);
    },
    postConnection(variables, options) {
      return requester(PostConnectionDocument, variables, options);
    },
    resourcesPage(variables, options) {
      return requester(ResourcesPageDocument, variables, options);
    },
    resourcesPageConnection(variables, options) {
      return requester(ResourcesPageConnectionDocument, variables, options);
    },
    resource(variables, options) {
      return requester(ResourceDocument, variables, options);
    },
    resourceConnection(variables, options) {
      return requester(ResourceConnectionDocument, variables, options);
    },
    legal(variables, options) {
      return requester(LegalDocument, variables, options);
    },
    legalConnection(variables, options) {
      return requester(LegalConnectionDocument, variables, options);
    },
    global(variables, options) {
      return requester(GlobalDocument, variables, options);
    },
    globalConnection(variables, options) {
      return requester(GlobalConnectionDocument, variables, options);
    }
  };
}
import { createClient } from "tinacms/dist/client";
const generateRequester = (client) => {
  const requester = async (doc, vars, options) => {
    let url = client.apiUrl;
    if (options?.branch) {
      const index = client.apiUrl.lastIndexOf("/");
      url = client.apiUrl.substring(0, index + 1) + options.branch;
    }
    const data = await client.request({
      query: doc,
      variables: vars,
      url
    }, options);
    return { data: data?.data, errors: data?.errors, query: doc, variables: vars || {} };
  };
  return requester;
};
export const ExperimentalGetTinaClient = () => getSdk(
  generateRequester(
    createClient({
      url: "https://content.tinajs.io/1.5/content/9494cc66-b36b-498a-81d9-35ab0f754918/github/main",
      queries
    })
  )
);
export const queries = (client) => {
  const requester = generateRequester(client);
  return getSdk(requester);
};
